
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Payment from '@/models/Payment'
import Invoice from '@/models/Invoice'
import WebMessage from '@/models/WebMessage'
import moment from 'moment'
import fields from './payments-home-fields'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
  },
})
export default class PaymentsHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public records: number = 0

  public loading: boolean = false

  public ready = false

  public query: string[] = []

  public selected: string[] = []

  public fieldFilters: any = {}

  public fields: Array<any> = []

  public show_filter_helper: boolean = false

  public query_settings = {
    invoice_fields: [
      {
        name: 'name',
        key: 'invoice_id',
        description: 'Include only the specified invoice name.',
      },
    ],
    custom_fields: [
      {
        name: 'status:canceled',
        value: 'status:canceled',
        description: 'Includes only records with canceled as status',
      },
      {
        name: 'status:confirmed',
        value: 'status:confirmed',
        description: 'Includes only records with confirmed as status',
      },
    ],
  }

  public filter_mode: string = 'exclusive'

  @Watch('filter_mode')
  public onFilterModeChange() {
    this.refresh()
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public mounted() {
    this.fields = fields
    this.loadFilters()
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()

    return Payment.paginate({
      page_size: 25,
      page: 1,
      order_by: 'created_at',
      order: 'desc',
      query: [...context.filter, ...field_filters],
      mode: this.filter_mode,
    }).then(({ records, data }) => {
      this.records = records
      this.loading = false
      return data
    })
  }

  public cancelPayment(payment: any) {
    payment.status = 'cancelled'
    payment.created_at = moment(payment.created_at).format('YYYY-MM-DD')
    payment.save().then(() => {
      this.dataTable.refresh()
    })
  }

  public confirmDelete(payment: Payment) {
    WebMessage.confirm(
      `Are you sure that you want to delete this payment from the invoice "<strong>${payment.invoice.name}</strong>"? You won't be able to restore it!`,
      'Delete payment',
    ).then((value: boolean) => {
      if (value) {
        payment.delete().then(() => {
          this.dataTable.refresh()
        })
      }
    })
  }

  private isQuickbooksLinked() {
    if (!this.is_quickbooks_connected) {
      WebMessage.error(
        'You need to connect your Quickbookks account before performing this action.',
        [
          {
            text: 'Connect Now!',
            action: (toast: any) => {
              this.$router.push({ name: 'Account' })
              WebMessage.hide(toast.id)
            },
          },
        ],
      )
      return false
    }

    return true
  }

  public createPayment() {
    if (!this.isQuickbooksLinked()) return

    this.$router.push({ name: 'payment' })
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.filter_mode = 'exclusive'
    this.clearFilters()
    this.refresh()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'payments',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('payments').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'payments',
      data: null,
    })
  }
}
