import Payment from '@/models/Payment'

export default [
  {
    key: 'id',
    label: 'ID',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
  },
  {
    key: 'invocie_number',
    label: 'Invoice #',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center ',
  },
  {
    key: 'invoice_name',
    label: 'Invoice name',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center ',
  },
  {
    key: 'payment_method',
    label: 'Payment Method',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    thStyle: { width: '10%' },
  },
  {
    key: 'amount',
    label: 'Amount',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'currency',
    thClass: 'align-middle text-center',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    type: 'badge',
    color: (payment: Payment) => (payment.status === 'confirmed' ? 'primary' : 'secondary'),
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    type: 'date',
  },
  {
    key: 'action',
    label: 'Action',
    sortable: false,
    show: true,
    thClass: 'align-middle text-center',
    thStyle: { width: '10%' },
  },

]
